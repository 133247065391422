import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

interface Option {
  value: string;
  label: string;
  flag?: any; // Add flag property
}

interface SimpleListMenuProps {
  options: Option[];
  defaultValueIndex?: number;
  onChange?: (value: string, index: number) => void;
}

const SelectAbleList: React.FC<SimpleListMenuProps> = ({
  options,
  defaultValueIndex = 0,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(defaultValueIndex);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (onChange) {
      onChange(options[index].value, index);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const imgSrc = options[selectedIndex].flag;
  return (
    <div>
      <List
        component="nav"
        aria-label="Options"
        sx={{
          bgcolor: "transparent",
        }}
      >
        <ListItemButton
          id="options-button"
          aria-haspopup="listbox"
          aria-controls="options-menu"
          aria-label="Options"
          aria-expanded={open ? "true" : undefined}
          sx={{
            gap: "6px",
            borderRadius: "6px",
            border: "1px solid rgba(0, 0, 0, 0.102)",
          }}
          onClick={handleClickListItem}
        >
          <ListItemText
            secondary={options[selectedIndex].label}
            secondaryTypographyProps={{ component: "span" }} // Render secondary text as span
            primaryTypographyProps={{ component: "span" }} // Render primary text as span
          />
          {imgSrc ? imgSrc : <></>}
        </ListItemButton>
      </List>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}
            sx={{ gap: "6px" }}
          >
            {imgSrc && option.flag}
            {/* Render flag */}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SelectAbleList;

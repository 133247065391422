import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Stack, Typography } from "@mui/material";

const SearchTag = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  marginTop: theme.spacing(0.9),
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const TitleTag = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    fontWeight: 600,
  },
}));

const SubTitleTag = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    fontWeight: 700,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    fontWeight: 600,
  },
}));
const PriceTag = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 700,
  color: "#E02020",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 700,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    fontWeight: 600,
  },

}));

const HeadingTag = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  fontWeight: 800,
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
    fontWeight: 800,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    fontWeight: 700,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 700,
  },
}));

const SubHeadingTag = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    fontWeight: 700,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    fontWeight: 600,
  },
}));

const StepHeadingTag = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  color: "0087F2",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 700,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const NameTag = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    fontWeight: 600,
  },
}));
const LabelTag = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const TextBox = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
}));

const CLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const Desc = styled("p")`
  margin: 20px 0px;
`;

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    border: "1px solid #ececec",
    background: "#ececec",
    borderRadius: "30px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const CustomStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
}));

export {
  SearchTag,
  NameTag,
  HeadingTag,
  SubHeadingTag,
  TextBox,
  CustomStack,
  StyledInputBase,
  TitleTag,
  SearchIconWrapper,
  Desc,
  CLink,
  SubTitleTag,
  PriceTag,
  StepHeadingTag,
  LabelTag,
};

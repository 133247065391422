import React from "react";
import { useTranslation } from "react-i18next";
import { CustomStack, SubHeadingTag, TitleTag } from "../CustomTags";
import { Box } from "@mui/material";
interface Props {
  cardData: IStepsCard;
}
function StepCard(props: Props) {
  const { t } = useTranslation();
  const { heading, detail, icon } = props.cardData;

  return (
    <CustomStack sx={{ gap: 0.1, flexDirection: "column", width: "100%" }}>
      <Box sx={{ maxWidth: "85px", width: "100%" }}>
        <img
          src={icon}
          alt="step icon"
          loading="lazy"
          style={{ width: "100%" }}
        />
      </Box>
      <Box sx={{ maxWidth: "280px", width: "100%", textAlign: "center" }}>
        <SubHeadingTag sx={{ color: "#0087F2", fontSize: "18px", }}>
          {heading}
        </SubHeadingTag>
        <TitleTag sx={{ display: { xs: "none", sm:"block" } }}>{detail}</TitleTag>
      </Box>
    </CustomStack>
  );
}

export default StepCard;

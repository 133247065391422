import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "sanitize.css/sanitize.css";

// Import root appÍ
import { App } from "app";
import store from "store/configureStore";

import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "reportWebVitals";

// Initialize languages
import "./locales/i18n";
// const openSansObserver = new FontFaceObserver("Baloo Bhaijaan 2", {});
// When Inter is loaded, add a font-family using Inter to the body

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const defaultTheme = createTheme();
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ThemeProvider>
    </HelmetProvider>
  </Provider>
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(["./locales/i18n"], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

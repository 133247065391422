import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CustomStack, DynamicBreadcrumbs } from "../Shared";
import { Box, Button, Container } from "@mui/material";
import ProductDetailThumb from "./ProductDetailThumb";
import { products } from "app/data/products";
import "./product.css";
import { useParams } from "react-router-dom";

function ProductDetail() {
    const smImgsRef = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState<IProduct | undefined>(undefined);
  const { productId } = useParams();

  const handleImageClick = (e: any,index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const product =
      products && products.find((item) => item.productId === productId);
    setData(product);
  }, [productId]);

  if (!data) {
    return <div>Product not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>detail page</title>
        <meta name="description" content="product detail page" />
      </Helmet>
      <Container>
        <DynamicBreadcrumbs
          items={[{ label: "Home", href: "/" }, { label: "Product Title" }]}
        />
        <CustomStack
          sx={{
            gap: 1,
            margin: "0 auto",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            key={data.productId}
            sx={{
              p: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                mb: 2,
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.102)",
              }}
            >
              <img
                src={data.images[activeIndex]}
                alt={data.itemName}
                width="100%"
              />
            </Box>
            {data && (
              <ProductDetailThumb
                activeIndex={activeIndex}
                images={data.images}
                handleImgChange={handleImageClick}
                smImgsRef={smImgsRef}
              />
            )}
          </Box>
          <Box sx={{}}>
            <h5>{data.itemName}</h5>
            <h3>€{data.price}</h3>
            <Button disabled variant="contained">Add to cart</Button>
          </Box>
        </CustomStack>
      </Container>
    </>
  );
}

export default ProductDetail;

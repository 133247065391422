import { step1, step2, step3, step4 } from "app/components/Assests";

export const enStepsTrans = [
  {
    icon: step1,
    heading: "Order Your Product",
    detail: "Choose your best products for fast and reliable delivery.",
  },
  {
    icon: step2,
    heading: "Same-Day Shipping",
    detail: "Fast, reliable, eco-friendly shipping from Germany.",
  },
  {
    icon: step3,
    heading: "Fast, Reliable Delivery",
    detail:
      "No worries, your order is in safe hands, our reliable delivery partners dpd DHL UPS.",
  },
  {
    icon: step4,
    heading: "Surprise at Your Doorstep",
    detail: "Secure your order, the doorstep awaits your surprise.",
  },
];

export const deStepsTrans = [
  {
    icon: step1,
    heading: "Bestellen Sie Ihr Produkt",
    detail:
      "Wählen Sie Ihre besten Produkte aus, um eine schnelle und zuverlässige Lieferung.",
  },
  {
    icon: step2,
    heading: "Versand am Selben Tag",
    detail:
      "Schneller, zuverlässiger Umweltfreundlicher Versand aus Deutschland.",
  },
  {
    icon: step3,
    heading: "Schnelle, Zuverlässige Lieferung",
    detail:
      "Keine Sorge, Ihre Bestellung ist in sicheren Diensten, unser Zuverlässige Lieferpartner dpd DHL UPS.",
  },
  {
    icon: step4,
    heading: "Überraschung vor der Haustür",
    detail:
      "Sichern Sie sich Ihre Bestellung, die Haustür wartet auf Ihre Überraschung.",
  },
];

import { useEffect, useState, useRef } from 'react';

interface ContainerDimensions {
  width: number;
  height: number;
}

const useContainerDimensions = (): { containerRef: React.RefObject<HTMLDivElement>; containerDimensions: ContainerDimensions; updateDimensions: () => void } => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerDimensions, setContainerDimensions] = useState<ContainerDimensions>({
    width: 0,
    height: 0,
  });

  const updateDimensions = () => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setContainerDimensions({
        width: offsetWidth,
        height: offsetHeight,
      });
    }
  };

  useEffect(() => {
    // Initial setup to get container dimensions
    updateDimensions();

    // Add event listener to handle container size changes
    window.addEventListener('resize', updateDimensions);

    // Cleanup by removing event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return { containerRef, containerDimensions, updateDimensions };
};

export default useContainerDimensions;

import React from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Badge, Button } from "@mui/material";
import Box from "@mui/material/Box";
import {
  CartIcon,
  MyOrderIcon,
  UserIcon,
  WishListIcon,
} from "app/components/Assests";
import { useTranslation } from "react-i18next";
import { useOpenCloseModal } from "Hooks";
import CustomModal from "./CustomModal";
import { Register } from "app/pages/Auth";
interface TopBarMenu {
  label: string;
  icon: any;
  callBack: () => void;
  badgeCount?: number;
}

function TopBar() {
  const { t } = useTranslation();
  const { closeModal, openModal, isOpen } = useOpenCloseModal();
  const topBarMenu: TopBarMenu[] = [
    {
      label: "loginRegister",
      icon: <UserIcon />,
      callBack: () => {
        openModal();
      },
    },
    {
      label: "myOrders",
      icon: <MyOrderIcon />,
      callBack: () => {
        // Callback function logic here
      },
    },
    {
      label: "wishlist",
      icon: <WishListIcon />,
      badgeCount: 0,
      callBack: () => {
        // Callback function logic here
      },
    },
    {
      label: "shoppingCart",
      icon: <CartIcon />,
      badgeCount: 0,
      callBack: () => {
        // Callback function logic here
      },
    },
  ];

  const MenuItem = (menu: TopBarMenu) => {
    const { callBack, icon, label, badgeCount } = menu;
    return (
      <Button
        aria-label="show 4 new mails"
        sx={{
          gap: "2px",
          flexWrap: "wrap-reverse",
          textTransform: "unset",
          fontSize: { xs: "10px", lg: "14px" },
          color: "black",
          fontWeight: 600,
          p: "3px 1px",
        }}
        onClick={callBack}
      >
        {t(label)}

        {badgeCount ? (
          <Badge badgeContent={badgeCount} color="error">
            {icon}
          </Badge>
        ) : (
          <>{icon}</>
        )}
      </Button>
    );
  };

  return (
    <Box
      sx={{ display: { xs: "none", md: "flex" }, gap: { md: 0.7, lg: 1.25 } }}
    >
      {topBarMenu.map((menu, i) => (
        <React.Fragment key={i}>{MenuItem(menu)}</React.Fragment>
      ))}

      <CustomModal
        open={isOpen}
        handleClose={closeModal}
        actionButtonText="Login"
        children={
            <Register closeModal={closeModal}/>
        }
        title=""
        handleAction={() => {
          closeModal();
        }}
      showActionButton={false}
      />
    </Box>
  );
}

export default TopBar;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { DeFlagIcon, EngSvgIcon, Logo } from "../Assests";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CustomStack,
  SearchIconWrapper,
  SearchTag,
  StyledInputBase,
  SubTitleTag,
} from "../Shared/CustomTags";

import SearchIcon from "@mui/icons-material/Search";

import useContainerDimensions from "Hooks/useContainerDimensions";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { SelectAbleList, TopBar } from "../Shared";

interface HeaderProps {
  setHeaderDimension: (containerDimensions: any) => void;
}
const selectedLocale = localStorage.getItem("i18nextLng");
const options = [
  { value: "en", label: "English", flag: <img src={EngSvgIcon} alt="" /> },
  { value: "de", label: "German", flag: <DeFlagIcon /> },
];

const findOptionIndex = options.findIndex(
  (opt) => opt.value === selectedLocale
);

const ProductOptions = [
  { value: "de", label: "procuct 1" },
  { value: "en", label: "procuct 2" },
  { value: "3", label: "procuct 3" },
  { value: "4", label: "procuct 4" },
];

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { t, i18n } = useTranslation();
  const { setHeaderDimension } = props;
  const { containerRef, containerDimensions } = useContainerDimensions();
  React.useEffect(() => {
    setHeaderDimension(containerDimensions);
  }, [containerDimensions.width, containerDimensions.height]);

  const handleOptionChange = (value: string, index: number) => {
    i18n.changeLanguage(value);
  };

  const btnStyle = {
    gap: "6px",
    flexWrap: "wrap-reverse",
    textTransform: "unset",
    fontSize: { xs: "12px", md: "14px", lg: "16px" },
    color: "black",
    fontWeight: 600,
    p: "5px 1px",
  };
  return (
    <AppBar
      ref={containerRef}
      position="fixed"
      sx={{
        background: "white",
        // pb: .1,
      }}
    >
      <Box sx={{ width: "100%", background: "#0087F2" }}>
        <SubTitleTag sx={{ textAlign: "center", py: 1, px: 0.4 }}>
          {t("title")}
        </SubTitleTag>
      </Box>
      <Toolbar
        sx={{
          gap: 1,
          allignment: "center",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon sx={{ color: "#375db1" }} />
        </IconButton>
        <Box sx={{ maxWidth: "100%" }}>
          <Logo />
        </Box>
        <SearchTag>
          <SearchIconWrapper>
            <SearchIcon
              sx={{
                color: "black",
              }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </SearchTag>

        <SelectAbleList
          options={options}
          defaultValueIndex={findOptionIndex > -1 ? findOptionIndex : 0}
          onChange={handleOptionChange}
        />
        <Box sx={{ flexGrow: 1 }} />
        <TopBar />
      </Toolbar>
      <Box sx={{ width: "100%", background: "#a1c9ea" }}>
        <CustomStack sx={{ justifyContent: "center", gap: { md: 1, lg: 2 } }}>
          <Button
            sx={btnStyle}
            // onClick={}
          >
            {t("menu.Homepage")}
          </Button>
          <Button
            sx={btnStyle}
            // onClick={}
          >
            {t("menu.Plush_Toy_Collection")}
          </Button>
          {/* <SelectAbleList options={ProductOptions} onChange={() => {}} /> */}
          <Button
            sx={btnStyle}
            // onClick={}
          >
            {t("menu.All_Products")}
          </Button>
          <Button
            sx={btnStyle}
            // onClick={}
          >
            {t("menu.Service")}
          </Button>
        </CustomStack>
      </Box>
    </AppBar>
  );
};

export default Header;

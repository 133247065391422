import React from "react";
import { CustomStack, TitleTag } from "../CustomTags";
import { InfoIcon } from "app/components/Assests";
import { useTranslation } from "react-i18next";

interface IProps {
  info: string;
}
function InfoTag({ info }: IProps) {
    const {t} = useTranslation()
  return (
    <CustomStack sx={{ gap: "6px" }}>
      <InfoIcon />
      <TitleTag>{t(info)}</TitleTag>
    </CustomStack>
  );
}

export default InfoTag;

import { MailOutline, PhoneOutlined, RoomOutlined } from "@mui/icons-material";
import { Box,  Grid, Stack } from "@mui/material";
import { Logo } from "app/components/Assests";
import { CLink, Desc, TitleTag } from "app/components/Shared/CustomTags";
import React from "react";

function Footer() {
  return (
    <Box sx={{ mt: 2, background: "#f6f6f6", width:'100%' }}>
      <Box sx={{ background: "#f6f6f6", maxWidth:'80%', margin: "0 auto", p: 2 }}>
        <Grid container gap={3} alignItems={"baseline"} wrap="wrap">
          <Grid item xs={12} md={4}>
             <Box sx={{ maxWidth: "130px", my: 2 }}>
              <Logo/>
            </Box>
          <Desc>
              Shopping with WIZTEX is easy and convenient. Simply browse our
              online store and choose from our wide selection of baby toys. We
              offer fast and reliable shipping, as well as excellent customer
              service to ensure that you are completely satisfied with your
              purchase.
            </Desc>
          </Grid>
          <Grid item xs={12}  md={3} sm={5} container flexDirection={"column"} gap={2} pt={1}>
            <TitleTag>Contact</TitleTag>
            <Stack flexDirection={"row"} gap={1}>
              <MailOutline />
              <CLink href="mailto:info@wiztex.de">info@wiztex.de</CLink>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <PhoneOutlined />
              <CLink href="tel:+05542-6134177">+05542-6134177</CLink>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <RoomOutlined />
              <CLink
                href="https://www.google.com/maps?q=bruekenstr.7,%2037213%20Witzenhausen"
                target="_blank"
              >
                bruekenstr.7, 37213 Witzenhausen
              </CLink>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} sm={5} container direction='column' gap={2}  pt={1} >
            <TitleTag>Useful Links</TitleTag>
            <CLink>Home</CLink>
            <CLink>My Account</CLink>
            <CLink>Wishlist</CLink>
            <CLink>Terms</CLink>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html {
    font-family: "Baloo Bhaijaan 2", sans-serif;
    font-optical-sizing: auto;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

  html,
  body {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
  }
  *,::after,::before {
    box-sizing: border-box
}

@-ms-viewport {
  width: device-width
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section {
  display: block
}

  body {
    font-family:  "Baloo Bhaijaan 2", sans-serif;
  }

  img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle
}

  #root {
    min-height: 100%;
    min-width: 100%;
  }
  p.MuiTypography-root, p,
  label {
    font-family:  "Baloo Bhaijaan 2", sans-serif;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
`;

import { Stack } from "@mui/material";
import { Header } from "app/components";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../../pages/Footer/Footer";
import { useResponsiveWindow } from "Hooks";
export function Layout(props: any) {
  const { width, height } = useResponsiveWindow();

  const [containerDimensions, setContainerDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const windowActualHeight = height - containerDimensions.height;
  return (
    <>
      <Helmet>
        <title>wiztex.de</title>
        <meta name="description" content="wiztex.de homepage" />
      </Helmet>
      <Header setHeaderDimension={setContainerDimensions} />
      <Stack
        sx={{
          mt: `${containerDimensions.height+10}px`,
          height: `${windowActualHeight}px`,
          overflow: "auto",
          p: 2,
        }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        {props.children}
        <Footer />
      </Stack>
    </>
  );
}

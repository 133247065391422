import {
  SonicFeature,
  SonicFrontBack,
  SonicTitle,
  variant1,
  variant2,
  variant3,
} from "app/components/Assests";

export const products: IProduct[] = [
  {
    itemName:
      "Wiztex Rain Bow Friends Kuscheltier - 30 cm Wiederverwendbar Rain Bow Friends Plüschtier - YouTube-Videos Stuffed Animal - Huggy Pooppy Wuggy Plush Toy Geschenk für Kinder und Spielefans (Blau)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. <br><br>Dieses Plüschtier ist ein kuscheliger Freund, perfekt für Glückwünsche, Geburtstage und mehr! Es ist ein kuscheliger Reisefreund auf Reisen und im Flugzeug oder eine schöne Schlafzimmerdekoration. Dieses Plüschtier der Regenbogenfreunde ist sehr lustig und ideal zum Spielen und Kuscheln und ein ideales Geschenk für alle Fans der Regenbogenfreunde. rainbow friends kuscheltier kuscheltiere plüschtier plüschtiere rainbow friends plüsch rainbow friends kuscheltier rainbow friends rainbow friends kuscheltier rainbow friends figuren roblox kuscheltier rainbow friends plüsch huggy wuggy",
    listingId: "0330A1KRJ1B",
    sellerSku: "3 Pcs Keyrings (Blue, Pink, Rainbow)",
    price: 11.0,
    openDate: "30/03/2023 20:13:11 BST",
    asin1: "B0BX4NLDHG",
    productId: "B0BX4NLDHG",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Sonic Plush Evil EXE Figure Collection, 28 cm Horror Stuffed Sonic Collection Cuddly Toy, Hedgehog Cotton Soft Animals, Animated Toys Children's Gift",
    itemDescription:
      "Sonic ist wegen seiner einzigartigen, energiegeladenen und leuchtend blauen Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihrer Lieblingsfigur zu kuscheln. Das Kissen ist aus geschmeidigem, luxuriösem Stoff gefertigt und bietet ein gemütliches Gefühl, in das man sich beim Schlafen einkuscheln kann oder das einen tollen Akzent in der Pause darstellt. Dieses niedliche Kissen zeigt Sonic the Hedgehog von Sega, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stachelige Haare hat. Ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren ihre Emotionen häufig durch zartes Spielzeug und nutzen es dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit empfindlichen Gegenständen neue Fähigkeiten erlernen.",
    listingId: "1212Z5TO98D",
    sellerSku: "EvilSonic",
    price: 16.99,
    openDate: "12/12/2022 16:59:28 GMT",
    asin1: "B0BPMJQY13",
    productId: "B0BPMJQY13",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [SonicTitle, SonicFeature, SonicFrontBack],
  },
  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Cuddly Toy, 40 cm Colorful, Soft and Fun Plush Toy | Sausage Monster Horror Stuffed Animal For Kids and Game Fans",
    itemDescription:
      'Reichhaltiges Spielzeug, das "Huggy Wuggy" darstellt, ein Computerspielmonster mit einem großen roten Mund und spitzen Zähnen, gelben Händen und Füßen und einem mit bunten Sprenkeln bedeckten Körper. Ein Grund für die Bekanntheit von Huggy Wuggy ist die Tatsache, dass "furchterregend" für Kinder und Erwachsene nicht immer die gleiche Bedeutung hat. Für manche ist der Anblick der zahlreichen Säulen mit messerscharfen Zähnen eher cool als furchterregend. Das monstergroße Spielzeug ist für alle Altersgruppen geeignet und spricht Kunden jeden Alters an. Lustige, auffällige Spielzeuge sind unglaublich faszinierend, sie üben eine große Anziehungskraft auf Ihre Kinder aus und fördern ihre Verspieltheit und ihren kreativen Geist. Dies ist das ideale Halloween-Geschenk für Kinder. Der Huggy Wuggy kann als Kopfkissen zum Schlafen, als Kissen für den Bürostuhl oder als Plüschtier, das bei allen Problemen hilft, verwendet werden. Als Heimwerker wird er Ihr Haus mit Liebe und Wärme erfüllen.',
    listingId: "0220AZXCVIL",
    sellerSku: "40-PNK-HW",
    price: 13.99,
    openDate: "20/02/2023 19:13:42 GMT",
    asin1: "B0BW42DMBY",
    productId: "B0BW42DMBY",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, Set of 5 Rainbow Friends Plush Toys, Beautifully Animated Plush Toy, Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Plüschtier ist ein kuscheliger Freund, perfekt für Glückwünsche, Geburtstage und mehr! Es ist ein kuscheliger Reisefreund auf Reisen und im Flugzeug oder eine schöne Schlafzimmerdekoration. Dieses Plüschtier der Regenbogenfreunde ist sehr lustig und ideal zum Spielen und Kuscheln und ein ideales Geschenk für alle Fans der Regenbogenfreunde.",
    listingId: "0324AIAHJFH",
    sellerSku: "7S-W29I-PGY9",
    price: 39.99,
    openDate: "24/03/2023 15:53:30 GMT",
    asin1: "B0BZ8LQBWP",
    productId: "B0BZ8LQBWP",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Cattle Horn Monster Plush Toy, Beautifully Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Cattle Horn Monster)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0317AWLKQA7",
    sellerSku: "BLK Horn Mnstr RF",
    price: 12.99,
    openDate: "17/03/2023 11:00:58 GMT",
    asin1: "B0BXY5J36K",
    productId: "B0BXY5J36K",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Blue Large Fist Monster Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Blue Big Fist Monster)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0322ABXXVZE",
    sellerSku: "BLU Big Fist Mnstr RF",
    price: 14.99,
    openDate: "22/03/2023 20:11:44 GMT",
    asin1: "B0BXY1KYNV",
    productId: "B0BXY1KYNV",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colourful Unicorn Toy | Unicorn Plush Toy with Wings for Kids | Best Toy for Kids | Unicorn Plush Toy for Baby Girls and Little Pony Fans (Aqua Blue Unicorn Crystal Purple Wings)",
    itemDescription:
      "Kuscheltiere gehören zu den Spielzeugen, die vor allem von Kindern gekauft werden. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene zu Anlässen wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstagen verwendet werden.   Ein ausgestopfter Einhorn-Plüsch ist die Art von Spielzeug, das sich Ihr Liebster wünschen würde. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses entzückende Spielzeug lieben. Plüschtiere für Mädchen sind toll! Kinder werden das Mini-Haustier mit wendbarem, weichem Kuschelanhänger lieben. Das Einhorn mit Flügeln ist mit entzückenden Farben und Blumen geschmückt. Dies ist ein tolles Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen!    Jede Art wird es genießen!   Diese Haustiertasche und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für Kinder ab 4 bis 9 Jahren. Komforttierspielzeug ist ein hervorragendes Geschenk für jeden Geburtstagskind!",
    listingId: "1126Z19REEX",
    sellerSku: "BLU UNICORN PRPL WNG",
    price: 21.67,
    openDate: "26/11/2022 16:20:24 GMT",
    asin1: "B0BN89478J",
    productId: "B0BN89478J",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Blue Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Blue)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AUQTT6P",
    sellerSku: "Blu Rainbow & Friends S",
    price: 12.99,
    openDate: "03/03/2023 20:03:16 GMT",
    asin1: "B0BX4NLDHG",
    productId: "B0BX4NLDHG",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Plush Blue Nursery Bag | Kishy Mishy, Backpack Kindergarten Bag, Plush Bag For Travel Best Children's Gift",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "1218ZM6MTBY",
    sellerSku: "Blue Huggy Wuggy New Bag",
    price: 12.99,
    openDate: "18/12/2022 13:26:05 GMT",
    asin1: "B0BQCGGQBV",
    productId: "B0BQCGGQBV",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Blue Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Blue)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0309A8GLZN8",
    sellerSku: "Blue Rainbow Friends Individual",
    price: 15.99,
    openDate: "09/03/2023 06:46:06 GMT",
    asin1: "B0BXM1CHQJ",
    productId: "B0BXM1CHQJ",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex 2023 Boxy Boo Project Playtime Plush Toy Collection, Project Playtime Boxy Boo 40 cm XXL Adorable and Cuddly Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox",
    itemDescription:
      "Dieses Project Playtime Boxy Boo Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Plüschtier ist ein kuscheliger Freund, perfekt für Glückwünsche, Geburtstage und mehr! Es ist ein kuscheliger Reisefreund auf Reisen und im Flugzeug oder eine schöne Schlafzimmerdekoration. Dieses Plüschtier der Project Playtime ist sehr lustig und ideal zum Spielen und Kuscheln und ein ideales Geschenk für alle Fans der Project Playtime.",
    listingId: "0306A1BZTT6",
    sellerSku: "Boxy Boo Rainbow Friends Plush",
    price: 15.99,
    openDate: "06/03/2023 15:00:26 GMT",
    asin1: "B0BXLZ748K",
    productId: "B0BXLZ748K",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Plush Toy | Beautiful Animated Plush Toy Inspired by Rob-lox and Rainbow Friends Fans (Bronze)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AUPRI8F",
    sellerSku: "Bronz Rainbow & Friends S",
    price: 12.99,
    openDate: "03/03/2023 19:34:25 GMT",
    asin1: "B0BX4RB769",
    productId: "B0BX4RB769",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Car Gear Knob for Toyota Land Cruiser 200 FJ20 2008-2017 | Automatic Gear Knob for Your Car Upgrade, ABS Quality Gear Lever Knob Black Walnut Colour",
    itemDescription:
      "Für Toyota LAND CRUISER 200 FJ20 2008 2009 2010 2011 2012 2013 2014 2015 2016 2017 AT Erhöht die Reibung und verbessert die Fahrsicherheit Faktoren. Verbessert Ihr Fahrerlebnis bei der Steuerung des Knopfes als seine komfortable Design. Dies ist die Vorrichtung, die verhindert, dass Sie aus dem Parken schalten, wenn Ihr Fuß nicht auf der Bremse ist. Wenn der Schalter im Bremspedal oder die Magnetspule selbst ausfällt, können Sie den Schalthebel nicht aus der Parkstellung bringen. Direkter Ersatz für den originalen Schaltknauf, großartiger Ersatz für Ihren alten Schaltknauf in schlechter Qualität oder ungeglättetem Gebrauch; Dieser Schaltknauf ist sowohl für Schaltgetriebe als auch für Fahrzeuge mit Automatikgetriebe geeignet.",
    listingId: "1220ZT2QFF3",
    sellerSku: "Car Gear Knob",
    price: 19.99,
    openDate: "20/12/2022 20:16:27 GMT",
    asin1: "B0BPHZ4VX6",
    productId: "B0BPHZ4VX6",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colorful Unicorn Bag, Running Dancing Plush Toy in Small Lovely Bag, Best Toy for Kids | Unicorn stuffed animal for baby girls and little horse fans (Dark Pink Pony in Pink Bag)",
    itemDescription:
      "Kuscheltiere gehören zu den beliebtesten Spielzeugen, besonders bei Kindern. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene für Ereignisse wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstage verwendet werden.  Ein ausgestopfter Einhorn-Plüsch mit einer liebenswerten Handtasche und Regenbogenfell ist die Art von Spielzeug, die Ihr Liebster haben möchte. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses bezaubernde Spielzeug lieben. Plüschtiere für Mädchen sind fantastisch! Die Mini-Haustiertasche mit wendbarem, weichem Kuschelanhänger wird Kindern gefallen. Die Einhorn-Handtasche ist mit bezaubernden Farben und Blumen bestickt. Dies ist ein fantastisches Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen! Jedes Kind wird es genießen! Diese Tragetasche für Haustiere und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für 4 bis 9 Jahre und mehr. Komfort-Tierspielzeuge sind ein hervorragendes Geschenk für jedes Geburtstagskind!",
    listingId: "0109AZ7B29G",
    sellerSku: "D PNK Pony PNK Bag",
    price: 19.99,
    openDate: "09/01/2023 09:36:13 GMT",
    asin1: "B0BN3XV5QN",
    productId: "B0BN3XV5QN",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "12 Inch Erasable Coloured LCD Writing Tablet | Drawing Pad for Learning for Children | Ultra Thin with New Electronic Technology Children (Pink Bear)",
    itemDescription:
      "Die multifunktionale LCD-Schreibtafel ist ein außergewöhnliches Lernspielzeug, das den kreativen Geist und die Innovationskraft junger Menschen fördert. Es wird typischerweise als Satztafel, Zeichenblock, Scribble Board, taktiles Spielzeug für geistiges Ungleichgewicht und mehr verwendet. Ein tolles Geschenk zum Kindertag, Schulanfang, Weihnachten, Geburtstag und Halloween für kleine Jungen und Mädchen. Dieses erstaunliche Tablet ermöglicht es Ihren Kindern oder Ihrer Familie, synthetische Farbe und Kreidestaub zu vermeiden. Eine Schreibtafel kann mehr als mehrmals entsorgt werden, wodurch 100.000 Blatt Papier eingespart werden, was 3 riesigen Bäumen entspricht. Die Planungsphase ist nicht nur unschädlich für das Ökosystem und bequem, sondern kann Ihnen auch helfen, Papierverschwendung, helle Bildschirme, keine Tinte, abnehmende empfindliche Akzeptanz und unterhaltsame Sprühfarbe zu vermeiden. Dank des Zeichentabletts machen sich die Kinder nicht an den Wänden schmutzig. Diese Magnettafel ist eine Planungsbühne zum Sprühen, Rechnen und Skizzieren für Kinder. Das LCD Composing Load-up ist ein tolles Spielzeug, über das Kinder diskutieren können, während sie in das Fahrzeug oder Flugzeug steigen. Das Schreibmodul kann auch verwendet werden, um Einkaufslisten, Besorgungen und Rezepte zu schreiben, Nachrichten an Verwandte weiterzuleiten oder tägliche Arbeitsnotizen und Erinnerungen bei der Arbeit zu erstellen. Bitte beachten Sie: Dieses LCD-Composing Board ist mit einem batteriebetriebenen Polymer-Akku ausgestattet. Der Bildschirm wird möglicherweise nicht gelöscht, wenn der Akku leer ist. Bitte laden Sie den Akku rechtzeitig auf.",
    listingId: "1011ZSRU4WD",
    sellerSku: "E-writer pink bear",
    price: 13.03,
    openDate: "11/10/2022 04:08:01 BST",
    asin1: "B0BH8CNFGS",
    productId: "B0BH8CNFGS",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Sonic the Hedgehog Plush Toy, 28 cm Blue Sonic Funny and Captivating Toy, Children's Toy Like Plush Toys",
    itemDescription:
      "Sonic ist wegen seiner einzigartigen, energiegeladenen und leuchtend blauen Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihrer Lieblingsfigur zu kuscheln. Das Kissen ist aus geschmeidigem, luxuriösem Stoff gefertigt und bietet ein gemütliches Gefühl, in das man sich beim Schlafen einkuscheln kann oder das einen tollen Akzent in der Pause darstellt. Dieses niedliche Kissen zeigt Sonic the Hedgehog von Sega, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stachelige Haare hat. Ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren ihre Emotionen häufig durch zartes Spielzeug und nutzen es dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit empfindlichen Gegenständen neue Fähigkeiten erlernen.",
    listingId: "0220AZT88TP",
    sellerSku: "EI-3CXW-D1MN",
    price: 14.99,
    openDate: "20/02/2023 18:31:43 GMT",
    asin1: "B0BW443QWX",
    productId: "B0BW443QWX",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },

  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Cuddly Toy, 40 cm Rainbow Soft and Fun Plush Toy | Sausage Monster Horror Plush Toy for Teens and Game Fans",
    itemDescription:
      'Reichhaltiges Spielzeug, das "Huggy Wuggy" darstellt, ein Computerspielmonster mit einem großen roten Mund und spitzen Zähnen, gelben Händen und Füßen und einem mit bunten Sprenkeln bedeckten Körper. Ein Grund für die Bekanntheit von Huggy Wuggy ist die Tatsache, dass "furchterregend" für Kinder und Erwachsene nicht immer die gleiche Bedeutung hat. Für manche ist der Anblick der zahlreichen Säulen mit messerscharfen Zähnen eher cool als furchterregend. Das monstergroße Spielzeug ist für alle Altersgruppen geeignet und spricht Kunden jeden Alters an. Lustige, auffällige Spielzeuge sind unglaublich faszinierend, sie üben eine große Anziehungskraft auf Ihre Kinder aus und fördern ihre Verspieltheit und ihren kreativen Geist. Dies ist das ideale Halloween-Geschenk für Kinder. Der Huggy Wuggy kann als Kopfkissen zum Schlafen, als Kissen für den Bürostuhl oder als Plüschtier, das bei allen Problemen hilft, verwendet werden. Als Heimwerker wird er Ihr Haus mit Liebe und Wärme erfüllen.',
    listingId: "0221A093HSV",
    sellerSku: "G8-IC3W-PR2T",
    price: 14.99,
    openDate: "21/02/2023 00:47:34 GMT",
    asin1: "B0BW42KVRR",
    productId: "B0BW42KVRR",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Green Dinosaur Rainbow Friends Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0309A8BJDMM",
    sellerSku: "Green Dinosour Plush Toy",
    price: 13.99,
    openDate: "09/03/2023 04:39:35 GMT",
    asin1: "B0BXMQFTW1",
    productId: "B0BXMQFTW1",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Green Throw Arrow Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Green Throwing Dart)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0316ASMNRNO",
    sellerSku: "Green Throwing Dart RF",
    price: 12.99,
    openDate: "16/03/2023 08:01:31 GMT",
    asin1: "B0BXY55YKN",
    productId: "B0BXY55YKN",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Rainbow Friends Character Doors)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0316AUPY0JZ",
    sellerSku: "Grey Long Monster RF",
    price: 11.99,
    openDate: "16/03/2023 21:58:28 GMT",
    asin1: "B0BXYFHMZN",
    productId: "B0BXYFHMZN",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Blue Huggy wuggy poppy playtime Kids Backpack, Daycare Bag, Poppy Playtime Horror Plush Backpack 3D Cartoon Kid's Plush Bag for Daycare and Travel",
    itemDescription:
      'Die Huggy Wuggy Plush Kindergartentasche ist bei Kindern und Jugendlichen berühmt dafür, dass sie nicht unbedingt eine ähnliche Bedeutung wie "gruselig" hat. Verschiedene Linien von gefährlich scharfen Zähnen mögen für manche eher cool als furchterregend sein. Huggy Wuggy ist eine der bekannten Figuren des bekannten Computerspiels huggy wuggy poppy playtime und ist bei Kindern außerordentlich berühmt, weshalb dieser plüschige Rucksack etwas ist, das Ihre Kinder schätzen werden. Er wird ihnen helfen, sich an den Schulalltag zu gewöhnen, starke Lerngewohnheiten zu entwickeln und sie vor kleinen Missgeschicken zu schützen, die bei vier- und fünfjährigen Kindern häufig vorkommen. Die Vorschulzeit ist der ideale Zeitpunkt, um Ihr Kind mit einem echten Schulrucksack vertraut zu machen und es auf den Erfolg in der Schule vorzubereiten.',
    listingId: "1103ZBN52TC",
    sellerSku: "HW-Blue",
    price: 14.99,
    openDate: "03/11/2022 06:40:51 GMT",
    asin1: "B0BK9DG5MK",
    productId: "B0BK9DG5MK",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Pink Huggy Wuggy Poppy Playtime Kids Backpack, Daycare Bag, Poppy Playtime Horror Plush Backpack 3D Cartoon Kid's Plush Bag for Daycare and Travel",
    itemDescription:
      'Die Huggy Wuggy Plush Kindergartentasche ist bei Kindern und Jugendlichen berühmt dafür, dass sie nicht unbedingt eine ähnliche Bedeutung wie "gruselig" hat. Verschiedene Linien von gefährlich scharfen Zähnen mögen für manche eher cool als furchterregend sein. Huggy Wuggy ist eine der bekannten Figuren des bekannten Computerspiels huggy wuggy poppy playtime und ist bei Kindern außerordentlich berühmt, weshalb dieser plüschige Rucksack etwas ist, das Ihre Kinder schätzen werden. Er wird ihnen helfen, sich an den Schulalltag zu gewöhnen, starke Lerngewohnheiten zu entwickeln und sie vor kleinen Missgeschicken zu schützen, die bei vier- und fünfjährigen Kindern häufig vorkommen. Die Vorschulzeit ist der ideale Zeitpunkt, um Ihr Kind mit einem echten Schulrucksack vertraut zu machen und es auf den Erfolg in der Schule vorzubereiten.',
    listingId: "1215ZEUJGA7",
    sellerSku: "HW-Pink",
    price: 13.99,
    openDate: "15/12/2022 14:59:42 GMT",
    asin1: "B0BK96LNRT",
    productId: "B0BK96LNRT",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Knuckles Plush The Hedgehog Sonic Plush Doll, 28 cm Black Sonic The Hedgehog Toy, Super Sonic Stuffed Toys and Cuddly Toys for Children",
    itemDescription:
      "Sonic Spielzeuge sind wegen ihrer einzigartigen, energiegeladenen und hellen schwarzen Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihren Lieblingsfiguren zu kuscheln. Diese Schwänze Plüsch Sonic the Hedgehog aus geschmeidigem, luxuriösem Stoff für ein gemütliches Gefühl, das fantastisch ist, um in zu kuscheln, wenn Sie schlafen oder als eine atemberaubende Pause Akzent. Dieses niedliche Kissen zeigt Sonic den Igel von Sega, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stacheliges Haar hat. ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren häufig ihre Emotionen durch diese zarten Plüschspielzeuge und nutzen sie dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit akustischen Stofftieren neue Fähigkeiten erlernen.",
    listingId: "0221A0Y9JTX",
    sellerSku: "Knuckles-Red-28cm",
    price: 14.99,
    openDate: "21/02/2023 06:44:09 GMT",
    asin1: "B0BTYZPZN1",
    productId: "B0BTYZPZN1",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Mommy Long Legs Children's Backpack | Huggy Wuggy Poppy Playtime Rainbow Bag | Soft Nursery Bag | Travel Backpack Best Gift, multicoloured",
    itemDescription:
      "Ein Grund dafür ist die huggy wuggy poppy playtime Plüsch-Schultasche, die bei Kindern und Erwachsenen beliebt ist. Dass es nicht immer eine ähnliche Bedeutung wie „beängstigend“ haben würde. Verschiedene Spuren von gefährlich scharfen Zähnen mögen für einige eher cool als erschreckend sein.    Daddys langbeiniger Charakter ist einer der berühmten Charaktere der bekannten Poppy-Spielzeit-Computererholung und ist bei Kindern sehr berühmt, weil dieser plüschige Rucksack etwas ist, das Ihre Kinder schätzen werden. Es wird ihnen helfen, sich mit der täglichen Arbeit des Colleges vertraut zu machen, erstklassige Bewertungsneigungen aufzubauen und einen Schutzschild gegen die kleinen Missgeschicke zu haben, die normalerweise 4- bis 5-jährige Kinder überspringen. Pre-K ist die hochwertige zweite, um Ihren Kleinen in einen echten Zweitbesetzungsrucksack zu verwandeln und früher als sie den Weg zum schulischen Erfolg zu bestimmen.",
    listingId: "1105ZGS1UMQ",
    sellerSku: "MLL-Bag",
    price: 16.99,
    openDate: "05/11/2022 09:57:58 GMT",
    asin1: "B0BK9DMD6R",
    productId: "B0BK9DMD6R",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Mommy Long Legs Plush Toy, Huggy Wuggy Poppy Playtime, 50 cm Pink XL Soft and Funny Plush Toy, Beautiful Cuddly Toy for Girls and Game Fans",
    itemDescription:
      'Mommy Long Legs, ein Computerspiel-Bösewicht, ist ein Hauptgegner in dem Horror-Videospiel huggy wuggy poppy playtime aus dem Jahr 2021 und dient als Hauptgegnerin in Kapitel 2. Sie kann ihren Körper nach Bedarf umgestalten und verbiegen und in einer Haltung mit längeren Beinen laufen, um mehr Geschwindigkeit zu erreichen. Ein Grund, warum huggy wuggy poppy playtime und seine Figuren so beliebt sind, ist, dass Kinder und Erwachsene "gruselig" nicht immer auf die gleiche Weise interpretieren. Dieses Huggy Wuggy Partnermonster-Spielzeug ist für Menschen aller Altersgruppen geeignet und macht ihnen Spaß. Lustige, aufwändige Spielzeuge sind wirklich faszinierend, sie sprechen Ihre Kinder an und regen ihre Fantasie und ihren Schabernack an.',
    listingId: "1216ZHV6AQ2",
    sellerSku: "Mommy Long Legs Plush Toy",
    price: 15.99,
    openDate: "16/12/2022 17:34:38 GMT",
    asin1: "B0BQCFT75L",
    productId: "B0BQCFT75L",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Blue Sonic Plush Doll, Cuddly Toy, 28 cm, Sonic Stuffed Toy, Sonic The Hedgehog Cotton Stuffed Toys for Children",
    itemDescription:
      "Sonic ist wegen seiner einzigartigen, energiegeladenen und leuchtend blauen Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihrer Lieblingsfigur zu kuscheln. Das Kissen ist aus geschmeidigem, luxuriösem Stoff gefertigt und eignet sich hervorragend zum Kuscheln beim Schlafen oder als toller Akzent in der Pause. Dieses niedliche Kissen zeigt Sonic Hedgehog von Wiztex, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stachelige Haare hat. Ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren ihre Emotionen häufig durch zartes Spielzeug und nutzen es dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit empfindlichen Gegenständen neue Fähigkeiten erlernen.",
    listingId: "0220AZS29MI",
    sellerSku: "NR-94MN-O7GQ",
    price: 12.99,
    openDate: "20/02/2023 16:52:49 GMT",
    asin1: "B0BTYKK98F",
    productId: "B0BTYKK98F",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colourful Unicorn Toy | Unicorn Plush Toy with Wings for Kids | Best Toy for Kids | Unicorn Plush Toy for Baby Girls and Little Pony Fans (Pink Unicorn Gold Wings)",
    itemDescription:
      "Kuscheltiere gehören zu den Spielzeugen, die vor allem von Kindern gekauft werden. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene zu Anlässen wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstagen verwendet werden.   Ein ausgestopfter Einhorn-Plüsch ist die Art von Spielzeug, das sich Ihr Liebster wünschen würde. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses entzückende Spielzeug lieben. Plüschtiere für Mädchen sind toll! Kinder werden das Mini-Haustier mit wendbarem, weichem Kuschelanhänger lieben. Das Einhorn mit Flügeln ist mit entzückenden Farben und Blumen geschmückt. Dies ist ein tolles Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen!    Jede Art wird es genießen!   Diese Haustiertasche und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für Kinder ab 4 bis 9 Jahren. Komforttierspielzeug ist ein hervorragendes Geschenk für jeden Geburtstagskind!",
    listingId: "1126Z17ZZ4B",
    sellerSku: "PNK UNICORN GOLDN WNG",
    price: 21.67,
    openDate: "26/11/2022 17:06:40 GMT",
    asin1: "B0BN86VB3L",
    productId: "B0BN86VB3L",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colourful Unicorn Toy | Unicorn Plush Toy with Wings for Kids | Best Toy for Kids | Unicorn Plush Toy for Baby Girls and Little Pony Fans (Purple Unicorn Golden Wings)",
    itemDescription:
      "Kuscheltiere gehören zu den Spielzeugen, die vor allem von Kindern gekauft werden. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene zu Anlässen wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstagen verwendet werden.   Ein ausgestopfter Einhorn-Plüsch ist die Art von Spielzeug, das sich Ihr Liebster wünschen würde. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses entzückende Spielzeug lieben. Plüschtiere für Mädchen sind toll! Kinder werden das Mini-Haustier mit wendbarem, weichem Kuschelanhänger lieben. Das Einhorn mit Flügeln ist mit entzückenden Farben und Blumen geschmückt. Dies ist ein tolles Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen!    Jede Art wird es genießen!   Diese Haustiertasche und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für Kinder ab 4 bis 9 Jahren. Komforttierspielzeug ist ein hervorragendes Geschenk für jeden Geburtstagskind!",
    listingId: "1126Z19Z4Z3",
    sellerSku: "PRPL UNICORN GOLDN WNG",
    price: 21.67,
    openDate: "26/11/2022 16:37:11 GMT",
    asin1: "B0BN885W8F",
    productId: "B0BN885W8F",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Plush Pink Nursery Bag | Kishy Mishy, Backpack Kindergarten Bag, Plush Bag For Travel Best Children's Gift",
    itemDescription:
      'Die Huggy Wuggy Plush Kindergartentasche ist bei Kindern und Jugendlichen berühmt dafür, dass sie nicht unbedingt eine ähnliche Bedeutung wie "gruselig" hat. Verschiedene Linien von gefährlich scharfen Zähnen mögen für manche eher cool als furchterregend sein. Huggy Wuggy ist eine der bekannten Figuren des bekannten Computerspiels huggy wuggy poppy playtime und ist bei Kindern außerordentlich berühmt, weshalb dieser plüschige Rucksack etwas ist, das Ihre Kinder schätzen werden. Er wird ihnen helfen, sich an den Schulalltag zu gewöhnen, starke Lerngewohnheiten zu entwickeln und sie vor kleinen Missgeschicken zu schützen, die bei vier- und fünfjährigen Kindern häufig vorkommen. Die Vorschulzeit ist der ideale Zeitpunkt, um Ihr Kind mit einem echten Schulrucksack vertraut zu machen und es auf den Erfolg in der Schule vorzubereiten.',
    listingId: "1218ZM9XLJW",
    sellerSku: "Pink Huggy Wuggy New Bag",
    price: 14.99,
    openDate: "18/12/2022 13:51:27 GMT",
    asin1: "B0BQCXQ872",
    productId: "B0BQCXQ872",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "PJ Pug a Pillar Plush Toy 83 cm XXL Huggy Wuggy Poppy Playtime Horror Figure Realistic Cuddly Toy for Storytelling Animated Caterpillar Plush Toy Gift for Kids (83 cm)",
    itemDescription:
      "<b>What activities can children do with the caterpillar plush?</b><br><br> The plush toy caterpillar could become a close friend of your kids. With a plush caterpillar, your kids can do the following things:<br><br> ~Give the plush caterpillar a nickname.<br> ~Build a home for a stuffed caterpillar.<br> ~Throw a party with a plush caterpillar and invite friends.<br> ~Share a book of stories.<br> ~Draw plush caterpillar pictures.<br> ~Hug and sleep with plush caterpillars.<br><br> <b>Benefits of caterpillar plush toy for kids:</b><br><br> We all know that Caterpillar plush toys bring comfort to your kids, but more than that, Caterpillar plush toys can help your kids become more social savvy. They use a plush caterpillar to play, eat, and entertain; These activities are known as role play and this role play will encourage them to interact with new people which will allow them to improve their social skills. Caterpillar plush toys offer advantages not only to children, but also to older children. According to studies, holding a stuffed animal helps reduce anxiety. Adding touches that mimic a human connection, like hugging a plush caterpillar, can be beneficial in treating depression, anxiety, and low self-esteem.",
    listingId: "0109AZJKIRV",
    sellerSku: "Pj Large",
    price: 18.99,
    openDate: "09/01/2023 11:35:01 GMT",
    asin1: "B0BHDXXHHG",
    productId: "B0BHDXXHHG",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex PJ Pug a Pillar Plush Toy 60 cm, Huggy Wuggy Poppy Playtime Horror Figure, Realistic Cuddly Toy for Storytelling, Animated Caterpillar Plush Toy Gift for Kids (60 cm)",
    itemDescription:
      "<b>What activities can children do with the caterpillar plush?</b><br><br> The plush toy caterpillar could become a close friend of your kids. With a plush caterpillar, your kids can do the following things:<br><br> ~Give the plush caterpillar a nickname.<br> ~Build a home for a stuffed caterpillar.<br> ~Throw a party with a plush caterpillar and invite friends.<br> ~Share a book of stories.<br> ~Draw plush caterpillar pictures.<br> ~Hug and sleep with plush caterpillars.<br><br> <b>Benefits of caterpillar plush toy for kids:</b><br><br> We all know that Caterpillar plush toys bring comfort to your kids, but more than that, Caterpillar plush toys can help your kids become more social savvy. They use a plush caterpillar to play, eat, and entertain; These activities are known as role play and this role play will encourage them to interact with new people which will allow them to improve their social skills. Caterpillar plush toys offer advantages not only to children, but also to older children. According to studies, holding a stuffed animal helps reduce anxiety. Adding touches that mimic a human connection, like hugging a plush caterpillar, can be beneficial in treating depression, anxiety, and low self-esteem.",
    listingId: "0109AZEY7EQ",
    sellerSku: "Pj Small",
    price: 15.99,
    openDate: "09/01/2023 10:15:42 GMT",
    asin1: "B0BHDVSNYT",
    productId: "B0BHDVSNYT",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Unicorn Shoulder Bag Rainbow Pop It Bag Stress Relief Shoulder Silicone Toy Bag for Children and Girls Best Gift for Girls, Rainbow multicolour",
    itemDescription:
      "Diese Umhängetasche hat einen Einhorn Farbverlauf für Mode. Die Multicolor wie lila, rosa, blau und gelb bilden einen schönen Farbverlauf in der gesamten Farbgestaltung. Als Ergebnis ist es sehr ansprechend für das Auge, und Mädchen werden zweifellos beeindruckt sein, wenn sie diese Tasche sehen. Die Tasche hat ein großes Fassungsvermögen und bietet Platz für Airpods, Taschentücher, Schreibwaren wie Stifte, Clips, Radiergummis, kleine Notizblöcke und Kosmetika wie Lippenstift, Make-up-Puder und so weiter.   Das Pop Wallet-Einhorn-Design kombiniert Push-Bubble-Spielzeug mit einer süßen Umhängetasche. Wenn du die Blasen zusammendrückst, macht das Zappelspielzeug ein leichtes Knallgeräusch, genießt das Gefühl des Zusammendrückens und baut effektiv Ängste und Stress ab. Bei Bedarf kann sie sowohl als modische Tasche als auch als Dekompressionsspielzeug verwendet werden. Die Brieftaschen-Schultertasche ist leicht und tragbar.   Sie kann als Geldbörse, Umhängetasche, niedliche Umhängetasche oder als Zappelspielzeug getragen werden. Ein gutes Zappelspielzeug für unterwegs, z. B. im Auto, Flugzeug, in der Schule, im Büro, im Restaurant, beim Camping oder auf Reisen. Überall eine gute Zeit haben. Fachleute aus dem Gesundheitswesen haben Zappelspielzeuge wie Pop It eingesetzt, um Kindern zu helfen, die unter Angstzuständen oder sensorischen Problemen leiden.",
    listingId: "1210ZZDYL1R",
    sellerSku: "Pop It Bag",
    price: 7.99,
    openDate: "10/12/2022 00:19:43 GMT",
    asin1: "B0BMXMCBLH",
    productId: "B0BMXMCBLH",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Purple Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Purple)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AUT0QRQ",
    sellerSku: "Purpl Rainbow & Friends S",
    price: 13.99,
    openDate: "03/03/2023 20:13:17 GMT",
    asin1: "B0BX4PHRZ1",
    productId: "B0BX4PHRZ1",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Huggy wuggy Poppy Playtime Cuddly Toy, 40 CM Rainbow Soft and Funny Plush Toy Children's Friends Plush Toy for Little Boys, Girls, Gift for Players and YouTube Fans (Rainbow)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0117AJNTZ04",
    sellerSku: "RANBO HUGY WUGY",
    price: 12.99,
    openDate: "17/01/2023 19:20:55 GMT",
    asin1: "B0BHQQK999",
    productId: "B0BHQQK999",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Plush Kids Mini Backpack, Rainbow Poppy Playtime Soft Plush Nursery Bag, Travel Bags (Pink/Blue) Kids Gift",
    itemDescription:
      "Die berühmte Huggy Wuggy Plüsch Kindergartentasche ist bei Kindern und Jugendlichen sehr beliebt. Huggy Wuggy ist einer der bekannten Charaktere des bekannten Computerspiels Poppy Playtime und ist außergewöhnlich berühmt unter Kindern, weshalb dieser Plüschrucksack etwas ist, das Ihre Kinder zu schätzen wissen werden. Er wird ihnen helfen, sich an den Schulalltag zu gewöhnen, starke Lerngewohnheiten zu entwickeln und sie vor kleinen Missgeschicken zu schützen, die bei vier- bis fünfjährigen Kindern häufig vorkommen. Die Vorschulzeit ist der ideale Zeitpunkt, um Ihr Kind mit einem echten Rucksack für die Schulanfänger vertraut zu machen und es auf den Erfolg im Kindergarten vorzubereiten.",
    listingId: "1103ZD5EB76",
    sellerSku: "RB-BLU-HW",
    price: 12.48,
    openDate: "03/11/2022 20:50:53 GMT",
    asin1: "B0BKSNSV1N",
    productId: "B0BKSNSV1N",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Huggy wuggy poppy playtime Plush Kids Mini Backpack, Rainbow Poppy Playtime Soft Plush Nursery Bag, Travel Bags (Pink/Blue) Kids Gift",
    itemDescription:
      "Die berühmte Huggy Wuggy Plüsch Kindergartentasche ist bei Kindern und Jugendlichen sehr beliebt. Huggy Wuggy ist einer der bekannten Charaktere des bekannten Computerspiels Poppy Playtime und ist außergewöhnlich berühmt unter Kindern, weshalb dieser Plüschrucksack etwas ist, das Ihre Kinder zu schätzen wissen werden. Er wird ihnen helfen, sich an den Schulalltag zu gewöhnen, starke Lerngewohnheiten zu entwickeln und sie vor kleinen Missgeschicken zu schützen, die bei vier- bis fünfjährigen Kindern häufig vorkommen. Die Vorschulzeit ist der ideale Zeitpunkt, um Ihr Kind mit einem echten Rucksack für die Schulanfänger vertraut zu machen und es auf den Erfolg im Kindergarten vorzubereiten.",
    listingId: "1103ZD1RXJB",
    sellerSku: "RB-PNK-HW",
    price: 12.49,
    openDate: "03/11/2022 21:13:42 GMT",
    asin1: "B0BKSRQ95K",
    productId: "B0BKSRQ95K",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Red Dinosaur Rainbow Friends Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0306A1WN6SG",
    sellerSku: "Red Dinosaur Plush Toy",
    price: 13.98,
    openDate: "06/03/2023 19:09:54 GMT",
    asin1: "B0BXN31Z27",
    productId: "B0BXN31Z27",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Plush Toy | Beautiful Animated Plush Toy Inspired by Rob-lox and Rainbow Friends Fans (Red)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AURDO7W",
    sellerSku: "Red Rainbow & Friends S",
    price: 12.99,
    openDate: "03/03/2023 20:03:16 GMT",
    asin1: "B0BX4MVH7M",
    productId: "B0BX4MVH7M",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Shadow The Hedgehog Sonic Plush Doll, 28 cm Black Sonic The Hedgehog Plush, Super Sonic Stuffed Toys and Cuddly Toys for Children",
    itemDescription:
      "Sonic ist wegen seiner einzigartigen, energiegeladenen und leuchtend gelben Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihrer Lieblingsfigur zu kuscheln. Das Kissen ist aus geschmeidigem, luxuriösem Stoff gefertigt und eignet sich hervorragend zum Kuscheln beim Schlafen oder als toller Akzent in der Pause. Dieses niedliche Kissen zeigt Sonic the Hedgehog, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stacheliges Haar hat. Ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren ihre Emotionen häufig durch zartes Spielzeug und nutzen es dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit empfindlichen Gegenständen neue Fähigkeiten erlernen.",
    listingId: "0221A0BX21P",
    sellerSku: "Shadow-BLK-10Feb",
    price: 12.99,
    openDate: "21/02/2023 01:32:15 GMT",
    asin1: "B0BVFYVRC1",
    productId: "B0BVFYVRC1",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, Rainbow Friends Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0402A8FGJ14",
    sellerSku: "TV Plush Toy Rainbow Friends",
    price: 13.99,
    openDate: "02/04/2023 02:04:37 BST",
    asin1: "B0C185W6M6",
    productId: "B0C185W6M6",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Sonic Miles Tails Power Plush Doll, 28 cm Yellow Plush Toy for Sonic Lovers Collection Cuddly Toys Hedgehog Cotton Stuffed Toys and Animated Toy for Children",
    itemDescription:
      "Sonic ist wegen seiner einzigartigen, energiegeladenen und leuchtend gelben Farbe zu einer beliebten Figur unter den Fans geworden. Nichts würde Ihre Kinder glücklicher machen, als mit ihrer Lieblingsfigur zu kuscheln. Das Kissen ist aus geschmeidigem, luxuriösem Stoff gefertigt und eignet sich hervorragend zum Kuscheln beim Schlafen oder als toller Akzent in der Pause. Dieses niedliche Kissen zeigt Sonic the Hedgehog, der wie die Figur aus dem bekannten Videospiel lustig geflochtene Augen und stacheliges Haar hat. Ein fantastischer Schlaf- und Kuschelbegleiter, der im Haus, im Auto, beim Aufbau des Lagers und sogar beim Spaß verwendet werden kann. Kleine Kinder kommunizieren ihre Emotionen häufig durch zartes Spielzeug und nutzen es dann, um Trost zu finden und Tugenden wie Freundlichkeit, Freundlichkeit und Halt zu entwickeln. Beim Spielen können Kinder mit empfindlichen Gegenständen neue Fähigkeiten erlernen.",
    listingId: "0220AZQ02WQ",
    sellerSku: "Tails-Miles",
    price: 13.99,
    openDate: "20/02/2023 17:11:34 GMT",
    asin1: "B0BPMLQKPP",
    productId: "B0BPMLQKPP",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Toothless Monster)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0321A82HXNX",
    sellerSku: "Toothless Monster RF",
    price: 14.3,
    openDate: "21/03/2023 18:29:26 GMT",
    asin1: "B0BYT3LJKQ",
    productId: "B0BYT3LJKQ",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Trash Monster Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Trash Monster)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0317AXW176Z",
    sellerSku: "Trash Mnstr RF",
    price: 13.99,
    openDate: "17/03/2023 23:43:23 GMT",
    asin1: "B0BXY5Z691",
    productId: "B0BXY5Z691",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Unicorn Rainbow Purple Fluffy Backpack, Mini Double Shoulder Backpack for Girls 3D Cartoon Kid's Fluffy Bag for School and Travel (Colourful), Rainbow multicolour",
    itemDescription:
      "Dieser bezaubernde Einhornsack stellt die berühmte Person der Kinderlieblingssendung MY Little Pony dar. Diese Show enthält eine grundlegende Handlung und brillant lustige Charaktere, die in der Lage sind, Bereiche der Stärke und der Gemeinschaft, des Vertrauens, der Rücksichtnahme und der Achtung bei Kindern zu entwickeln. Sie hat ebenfalls einige verblüffende Szenen und visuelle Bilder, und das bedeutet, dass sie ideal für Kinder ist, die älter als 5 Jahre sind. Dieser Plüschrucksack ist etwas, das Ihre Kinder zu schätzen wissen werden. Mit seiner lebhaften, charmanten Kreatur und dem angenehmen, extravaganten Material wird der Baby-Rucksack nicht nur von Kindern gerne gestreichelt. Außerdem können sie mit diesem niedlichen Rucksack nicht nur ausgehen, sondern auch ihren grenzenlosen kreativen Geist anregen, egal ob die Kleinen in die Schule oder zum Picknick gehen. Dieser niedliche Einhorn-Rucksack ist aus exzellentem, zartem Sparsamkeitsmaterial und Reißverschluss-Verzierungen gefertigt und eignet sich für kleine Mädchen, süße Jungen und kleine Kinder. Er bietet ausreichend Platz für Kindersachen wie Spielzeug, Lebensmittel, Bioprodukte, kleine Bücher, Stiftsäcke, andere Schreibmaterialien und so weiter. Die besten Geschenke für die Erziehungssaison der Kinder, tolle Geschenke für Jugendliche, kleine Prinzen.",
    listingId: "1206ZP0Q8HK",
    sellerSku: "Unicorn Rainbow Light Purple Back Pack",
    price: 19.99,
    openDate: "06/12/2022 11:06:10 GMT",
    asin1: "B0BNR4RJ1P",
    productId: "B0BNR4RJ1P",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Unicorn Fluffy Backpack, Nursery Bag, 3D Cartoon Kid's Fluffy Bag for School and Travel (Colourful), Rainbow multicolour",
    itemDescription:
      "Dieser bezaubernde Einhornsack stellt die berühmte Person der Kinderlieblingssendung MY Little Pony dar. Diese Show enthält eine grundlegende Handlung und brillant lustige Charaktere, die in der Lage sind, Bereiche der Stärke und der Gemeinschaft, des Vertrauens, der Rücksichtnahme und der Achtung bei Kindern zu entwickeln. Sie hat ebenfalls einige verblüffende Szenen und visuelle Bilder, und das bedeutet, dass sie ideal für Kinder ist, die älter als 5 Jahre sind. Dieser Plüschrucksack ist etwas, das Ihre Kinder zu schätzen wissen werden. Mit seiner lebhaften, charmanten Kreatur und dem angenehmen, extravaganten Material wird der Baby-Rucksack nicht nur von Kindern gerne gestreichelt. Außerdem können sie mit diesem niedlichen Rucksack nicht nur ausgehen, sondern auch ihren grenzenlosen kreativen Geist anregen, egal ob die Kleinen in die Schule oder zum Picknick gehen. Dieser niedliche Einhorn-Rucksack ist aus exzellentem, zartem Sparsamkeitsmaterial und Reißverschluss-Verzierungen gefertigt und eignet sich für kleine Mädchen, süße Jungen und kleine Kinder. Er bietet ausreichend Platz für Kindersachen wie Spielzeug, Lebensmittel, Bioprodukte, kleine Bücher, Stiftsäcke, andere Schreibmaterialien und so weiter. Die besten Geschenke für die Erziehungssaison der Kinder, tolle Geschenke für Jugendliche, kleine Prinzen.",
    listingId: "1206ZOV6XXP",
    sellerSku: "Unicorn Rainbow Multicolor Back Pack",
    price: 17.99,
    openDate: "06/12/2022 11:06:10 GMT",
    asin1: "B0BNQZ1QJX",
    productId: "B0BNQZ1QJX",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Unicorn Rainbow Pink Fluffy Backpack, Nursery Bag, 3D Cartoon Kid's Fluffy Bag for School and Travel (Colourful), Rainbow multicolour",
    itemDescription:
      "Dieser bezaubernde Einhornsack stellt die berühmte Person der Kinderlieblingssendung MY Little Pony dar. Diese Show enthält eine grundlegende Handlung und brillant lustige Charaktere, die in der Lage sind, Bereiche der Stärke und der Gemeinschaft, des Vertrauens, der Rücksichtnahme und der Achtung bei Kindern zu entwickeln. Sie hat ebenfalls einige verblüffende Szenen und visuelle Bilder, und das bedeutet, dass sie ideal für Kinder ist, die älter als 5 Jahre sind. Dieser Plüschrucksack ist etwas, das Ihre Kinder zu schätzen wissen werden. Mit seiner lebhaften, charmanten Kreatur und dem angenehmen, extravaganten Material wird der Baby-Rucksack nicht nur von Kindern gerne gestreichelt. Außerdem können sie mit diesem niedlichen Rucksack nicht nur ausgehen, sondern auch ihren grenzenlosen kreativen Geist anregen, egal ob die Kleinen in die Schule oder zum Picknick gehen. Dieser niedliche Einhorn-Rucksack ist aus exzellentem, zartem Sparsamkeitsmaterial und Reißverschluss-Verzierungen gefertigt und eignet sich für kleine Mädchen, süße Jungen und kleine Kinder. Er bietet ausreichend Platz für Kindersachen wie Spielzeug, Lebensmittel, Bioprodukte, kleine Bücher, Stiftsäcke, andere Schreibmaterialien und so weiter. Die besten Geschenke für die Erziehungssaison der Kinder, tolle Geschenke für Jugendliche, kleine Prinzen.",
    listingId: "1206ZOUBUQL",
    sellerSku: "Unicorn Rainbow Pink Back Pack",
    price: 16.99,
    openDate: "06/12/2022 09:26:48 GMT",
    asin1: "B0BNQYLHKN",
    productId: "B0BNQYLHKN",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Unicorn Rainbow Yellow Fluffy Backpack, Nursery Bag Kita Tache, 3D Cartoon Children's Fluffy Bag for School and Travel (Colourful), Rainbow multicolour",
    itemDescription:
      "Dieser bezaubernde Einhornsack stellt die berühmte Person der Kinderlieblingssendung MY Little Pony dar. Diese Show enthält eine grundlegende Handlung und brillant lustige Charaktere, die in der Lage sind, Bereiche der Stärke und der Gemeinschaft, des Vertrauens, der Rücksichtnahme und der Achtung bei Kindern zu entwickeln. Sie hat ebenfalls einige verblüffende Szenen und visuelle Bilder, und das bedeutet, dass sie ideal für Kinder ist, die älter als 5 Jahre sind. Dieser Plüschrucksack ist etwas, das Ihre Kinder zu schätzen wissen werden. Mit seiner lebhaften, charmanten Kreatur und dem angenehmen, extravaganten Material wird der Baby-Rucksack nicht nur von Kindern gerne gestreichelt. Außerdem können sie mit diesem niedlichen Rucksack nicht nur ausgehen, sondern auch ihren grenzenlosen kreativen Geist anregen, egal ob die Kleinen in die Schule oder zum Picknick gehen. Dieser niedliche Einhorn-Rucksack ist aus exzellentem, zartem Sparsamkeitsmaterial und Reißverschluss-Verzierungen gefertigt und eignet sich für kleine Mädchen, süße Jungen und kleine Kinder. Er bietet ausreichend Platz für Kindersachen wie Spielzeug, Lebensmittel, Bioprodukte, kleine Bücher, Stiftsäcke, andere Schreibmaterialien und so weiter. Die besten Geschenke für die Erziehungssaison der Kinder, tolle Geschenke für Jugendliche, kleine Prinzen.",
    listingId: "1206ZOSUFS0",
    sellerSku: "Unicorn Rainbow Yellow Back Pack",
    price: 21.99,
    openDate: "06/12/2022 10:17:25 GMT",
    asin1: "B0BNQXSGKY",
    productId: "B0BNQXSGKY",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colourful unicorn with handbag, singing and dancing plush toy in small beautiful bag, best toy for children, unicorn stuffed toy for baby girls and small pony fans (white pony red bag)",
    itemDescription:
      "Kuscheltiere gehören zu den beliebtesten Spielzeugen, besonders bei Kindern. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene für Ereignisse wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstage verwendet werden.  Ein ausgestopfter Einhorn-Plüsch mit einer liebenswerten Handtasche und Regenbogenfell ist die Art von Spielzeug, die Ihr Liebster haben möchte. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses bezaubernde Spielzeug lieben. Plüschtiere für Mädchen sind fantastisch! Die Mini-Haustiertasche mit wendbarem, weichem Kuschelanhänger wird Kindern gefallen. Die Einhorn-Handtasche ist mit bezaubernden Farben und Blumen bestickt. Dies ist ein fantastisches Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen! Jedes Kind wird es genießen! Diese Tragetasche für Haustiere und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für 4 bis 9 Jahre und mehr. Komfort-Tierspielzeuge sind ein hervorragendes Geschenk für jedes Geburtstagskind!",
    listingId: "1123ZUF5V15",
    sellerSku: "WHT Pony RED Bag",
    price: 19.99,
    openDate: "23/11/2022 23:25:36 GMT",
    asin1: "B0BN3Z8K9B",
    productId: "B0BN3Z8K9B",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Colourful Unicorn Toy | Unicorn Plush Toy with Wings for Kids | Best Toy for Kids | Unicorn Plush Toy for Baby Girls and Little Pony Fans (White Unicorn Purple Wings)",
    itemDescription:
      "Kuscheltiere gehören zu den Spielzeugen, die vor allem von Kindern gekauft werden. Sie können für fantasievolles Spielen, Trostobjekte, Ausstellung oder Sammlung und Geschenke für Kinder und Erwachsene zu Anlässen wie Abschluss, Krankheit, Beileid, Valentinstag, Weihnachten oder Geburtstagen verwendet werden.   Ein ausgestopfter Einhorn-Plüsch ist die Art von Spielzeug, das sich Ihr Liebster wünschen würde. Wenn Sie Kuscheltiere und Teddybären mögen, werden Sie dieses entzückende Spielzeug lieben. Plüschtiere für Mädchen sind toll! Kinder werden das Mini-Haustier mit wendbarem, weichem Kuschelanhänger lieben. Das Einhorn mit Flügeln ist mit entzückenden Farben und Blumen geschmückt. Dies ist ein tolles Einhorn-Taschenspielzeug. Kleine Mädchen auf der ganzen Welt lieben diese magischen Kreaturen!    Jede Art wird es genießen!   Diese Haustiertasche und das Plüsch-Spielset werden Sie stundenlang unterhalten. Mit einem Schnellzugriffsband kann der Plüsch entfernt werden. Ein tolles Geburtstagsgeschenk für Kinder ab 4 bis 9 Jahren. Komforttierspielzeug ist ein hervorragendes Geschenk für jeden Geburtstagskind!",
    listingId: "1126Z156UIB",
    sellerSku: "WHT UNICORN PRPL WNG",
    price: 21.67,
    openDate: "26/11/2022 16:20:24 GMT",
    asin1: "B0BN886888",
    productId: "B0BN886888",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Huggy wuggy poppy playtime Plush Toy, 40cm White Soft and Shiny Stuffed Animal | Kids Friends Plush Toy for Little Boys Girls Gift for Gamers and YouTube Fans",
    itemDescription:
      'AUSGEWÄHLTES GESCHENK UND BRINGEN SPASS!!   Reichhaltiges Spielzeug, das "Huggy Wuggy" darstellt, ein Computerspielmonster mit einem großen roten Mund und spitzen Zähnen, gelben Händen und Füßen und einem mit bunten Sprenkeln bedeckten Körper. Ein Grund für die Bekanntheit von Huggy Wuggy ist die Tatsache, dass "furchterregend" für Kinder und Erwachsene nicht immer die gleiche Bedeutung hat. Für manche ist der Anblick der zahlreichen Säulen mit messerscharfen Zähnen eher cool als furchterregend. Das monstergroße Spielzeug ist für alle Altersgruppen geeignet und spricht Kunden jeden Alters an. Lustige, auffällige Spielzeuge sind unglaublich faszinierend, sie üben eine große Anziehungskraft auf Ihre Kinder aus und fördern ihre Verspieltheit und ihren kreativen Geist. Dies ist das ideale Halloween-Geschenk für Kinder. Der Huggy Wuggy kann als Kopfkissen zum Schlafen, als Kissen für den Bürostuhl oder als Plüschtier, das bei allen Problemen hilft, verwendet werden. Als Heimwerker wird er Ihr Haus mit Liebe und Wärme erfüllen.',
    listingId: "1217ZK12CM5",
    sellerSku: "White-Huggy-Wuggy",
    price: 14.99,
    openDate: "17/12/2022 13:21:35 GMT",
    asin1: "B0BKW5LT6D",
    productId: "B0BKW5LT6D",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, Yellow Cute Stuffed Toy, Rainbow Friends Plush Toy, Beautiful Animated Plush Toy, Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0402A8L7PDF",
    sellerSku: "Yellow Chicken Rainbow Friends",
    price: 12.99,
    openDate: "02/04/2023 01:56:04 BST",
    asin1: "B0C1872D6B",
    productId: "B0C1872D6B",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Clownball Plush Toy, Beautifully Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Yellow Clown Ball)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0321A85BV3S",
    sellerSku: "Yellow Clown Ball RF",
    price: 13.99,
    openDate: "21/03/2023 18:29:26 GMT",
    asin1: "B0BXYBGD61",
    productId: "B0BXYBGD61",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Party Monster Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Yellow Party Monster)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0316ASR0B9I",
    sellerSku: "Yellow Part Mnstr RF",
    price: 12.99,
    openDate: "16/03/2023 08:07:25 GMT",
    asin1: "B0BXY4X5QH",
    productId: "B0BXY4X5QH",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Yellow)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AUO759W",
    sellerSku: "Yellow Rainbow & Friends S",
    price: 11.99,
    openDate: "03/03/2023 19:34:25 GMT",
    asin1: "B0BX4NCRXR",
    productId: "B0BX4NCRXR",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0306A1CVB2X",
    sellerSku: "Yellow Rainbow Friends Individual",
    price: 13.99,
    openDate: "06/03/2023 15:56:36 GMT",
    asin1: "B0BXLY86HV",
    productId: "B0BXLY86HV",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Yellow Water Drop Plush Toy, Beautifully Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Yellow Water Drop)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0317AXBS1SZ",
    sellerSku: "Yellow Water Drop RF",
    price: 12.99,
    openDate: "17/03/2023 17:29:05 GMT",
    asin1: "B0BXXXTVX6",
    productId: "B0BXXXTVX6",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Active",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Poppy Playtime Bunzo Bunny Plush Toy, 40 cm Lovely Friendly Plush Toy Children's Favorite Game Character Soft Stuffed Pillow Doll Gift for Gamers, Kids and Fans",
    itemDescription:
      "Bunzo Bunny ist neben den Huggy Buddies und PJ Pug-A-Pillar einer der drei Nebenfeinde in Kapitel 2 von Poppy Playtime. Bunzo Bunny ist ein gelber Hase mit zwei langen gelben Ohren, zwei Hasenzähnen, grüner Gesichtsfarbe und schwarzen Augen. Außerdem trägt er eine grüne Latzhose mit zwei großen grünen Knöpfen und einen grünen Partyhut. Er trägt zwei große gold/braune Becken. Wenn er irritiert ist, zeigt er ein Paar scharfe Zähne in seinem Kiefer. Im Horror/Puzzle-Spiel Poppy Playtime muss der Spieler eine verlassene Spielzeugfabrik erkunden, um herauszufinden, was passiert ist. Wenn dir das Spiel gefällt, solltest du unseren Plüsch besitzen. Die weiche Oberfläche kann Kinder zum fröhlichen Spielen anregen. Dies ist ein Begleiter für Kinder und Erwachsene, nicht nur ein Spielzeug. Der Bunzo-Hase kann als Schlafkissen oder als Plüschtier verwendet werden.",
    listingId: "0204AQA7NE9",
    sellerSku: "5V-PDQH-R27D",
    price: 14.99,
    openDate: "04/02/2023 12:58:41 GMT",
    asin1: "B0BKSVJ6SM",
    productId: "B0BKSVJ6SM",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Inactive",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "wiztex Poppy Playtime Bunzo Bunny Plush Toy, 40 cm Lovely Friendly Plush Toy Children's Favorite Game Character Soft Stuffed Pillow Doll Gift for Gamers, Kids and Fans",
    itemDescription:
      "Bunzo Bunny ist neben den Huggy Buddies und PJ Pug-A-Pillar einer der drei Nebenfeinde in Kapitel 2 von Poppy Playtime. Bunzo Bunny ist ein gelber Hase mit zwei langen gelben Ohren, zwei Hasenzähnen, grüner Gesichtsfarbe und schwarzen Augen. Außerdem trägt er eine grüne Latzhose mit zwei großen grünen Knöpfen und einen grünen Partyhut. Er trägt zwei große gold/braune Becken. Wenn er irritiert ist, zeigt er ein Paar scharfe Zähne in seinem Kiefer. Im Horror/Puzzle-Spiel Poppy Playtime muss der Spieler eine verlassene Spielzeugfabrik erkunden, um herauszufinden, was passiert ist. Wenn dir das Spiel gefällt, solltest du unseren Plüsch besitzen. Die weiche Oberfläche kann Kinder zum fröhlichen Spielen anregen. Dies ist ein Begleiter für Kinder und Erwachsene, nicht nur ein Spielzeug. Der Bunzo-Hase kann als Schlafkissen oder als Plüschtier verwendet werden.",
    listingId: "0318AZFO6T9",
    sellerSku: "Bunzo-Bunny",
    price: 12.38,
    openDate: "18/03/2023 14:16:02 GMT",
    asin1: "B0BKSVJ6SM",
    productId: "B0BKSVJ6SM",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Inactive",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "12 Inch LCD Erasable Coloured Writing Tablet | Drawing Pad Learning for Children | Ultra Thin with New Electronic Technology Children (Blue Bear)",
    itemDescription:
      "Die multifunktionale LCD-Schreibtafel ist ein außergewöhnliches Lernspielzeug, das den kreativen Geist und die Innovationskraft junger Menschen fördert. Es wird typischerweise als Satztafel, Zeichenblock, Scribble Board, taktiles Spielzeug für geistiges Ungleichgewicht und mehr verwendet. Ein tolles Geschenk zum Kindertag, Schulanfang, Weihnachten, Geburtstag und Halloween für kleine Jungen und Mädchen. Dieses erstaunliche Tablet ermöglicht es Ihren Kindern oder Ihrer Familie, synthetische Farbe und Kreidestaub zu vermeiden. Eine Schreibtafel kann mehr als mehrmals entsorgt werden, wodurch 100.000 Blatt Papier eingespart werden, was 3 riesigen Bäumen entspricht. Die Planungsphase ist nicht nur unschädlich für das Ökosystem und bequem, sondern kann Ihnen auch helfen, Papierverschwendung, helle Bildschirme, keine Tinte, abnehmende empfindliche Akzeptanz und unterhaltsame Sprühfarbe zu vermeiden. Dank des Zeichentabletts machen sich die Kinder nicht an den Wänden schmutzig. Diese Magnettafel ist eine Planungsbühne zum Sprühen, Rechnen und Skizzieren für Kinder. Das LCD Composing Load-up ist ein tolles Spielzeug, über das Kinder diskutieren können, während sie in das Fahrzeug oder Flugzeug steigen. Das Schreibmodul kann auch verwendet werden, um Einkaufslisten, Besorgungen und Rezepte zu schreiben, Nachrichten an Verwandte weiterzuleiten oder tägliche Arbeitsnotizen und Erinnerungen bei der Arbeit zu erstellen. Bitte beachten Sie: Dieses LCD-Composing Board ist mit einem batteriebetriebenen Polymer-Akku ausgestattet. Der Bildschirm wird möglicherweise nicht gelöscht, wenn der Akku leer ist. Bitte laden Sie den Akku rechtzeitig auf.",
    listingId: "1009ZPCV044",
    sellerSku: "E-writer Blue bear",
    price: 13.09,
    openDate: "09/10/2022 17:28:34 BST",
    asin1: "B0BH4V5K4F",
    productId: "B0BH4V5K4F",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Inactive",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Green Plush Toy | Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans (Green)",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0303AUQP4HF",
    sellerSku: "Gren Rainbow & Friends S",
    price: 13.99,
    openDate: "03/03/2023 19:34:25 GMT",
    asin1: "B0BX4QLR3Q",
    productId: "B0BX4QLR3Q",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Inactive",
    images: [variant1, variant2, variant3],
  },
  {
    itemName:
      "Wiztex Rainbow Friends Plush Toy Collection, 30 cm Rainbow Plush Toy, Beautiful Animated Plush Toy Inspired by Roblox and Rainbow Friends Fans",
    itemDescription:
      "Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität. Dieses Regenbogen-Kuscheltier ist beliebt, weil es ein Horrorspiel ist, genau wie Piggy, Five Nights at Freddy's und Poppy Playtime. Ein verdrehtes Monster fegte durch alles, genau wie in dem Spiel! Es wird dich wie ein Freund begleiten, es ist weich und bequem, fühlt sich zart an und hat eine ausgezeichnete Elastizität.",
    listingId: "0306A1SPYCS",
    sellerSku: "Rainbow Rainbow Friends Individuals",
    price: 14.99,
    openDate: "06/03/2023 19:27:28 GMT",
    asin1: "B0BXLZZ2FH",
    productId: "B0BXLZZ2FH",
    fulfillmentChannel: "AMAZON_EU",
    merchantShippingGroup: "Migrated Template",
    status: "Inactive",
    images: [variant1, variant2, variant3],
  },
];

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Grid } from "@mui/material";
import { CustomStack } from "../CustomTags";
type DialogProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleAction: () => void;
  actionButtonText: string;
  children: any;
  showActionButton?: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const CustomModal: React.FC<DialogProps> = ({
  open,
  title,
  handleClose,
  handleAction,
  actionButtonText,
  children,
  showActionButton = true,
}) => {
  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <CustomStack sx={{ justifyContent: "space-between", p: 1, textTransform: "unset" }}>
          <DialogTitle>{title}</DialogTitle>
          <Button onClick={handleClose}>Close</Button>
        </CustomStack>
        <DialogContent>{children}</DialogContent>
        {showActionButton && (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAction} autoFocus>
              {actionButtonText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default CustomModal;

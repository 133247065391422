import React from "react";
import { CustomStack, HeadingTag, StepHeadingTag } from "../CustomTags";
import { Subtitles } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { deStepsTrans, enStepsTrans } from "contants/steps";
import { Box } from "@mui/material";
import StepCard from "./StepCard";

function ShippingSteps() {
  const { t } = useTranslation();
  const selectedLocale = localStorage.getItem("i18nextLng");

  const findStep = {
    en: enStepsTrans,
    de: deStepsTrans,
  };

 const steps: IStepsCard[] = findStep[selectedLocale as keyof typeof findStep] || deStepsTrans;

  return (
    <Box sx={{ margin: "0 auto"}} >
      <CustomStack sx={{ gap: 0.1, flexDirection: "column" }}>
        <HeadingTag>{t("info.Get_your_Toys_Steps")}</HeadingTag>
        <StepHeadingTag color="#862C9C">
          {t("info.hassel_Free_shipping")}
        </StepHeadingTag>
      </CustomStack>
      <CustomStack sx={{alignItems:{md:"flex-end",xs:"flex-start"},gap:1, mx:1.25}}>
        {steps.map((cardData, index) => (
          <StepCard key={index} cardData={cardData} />
        ))}
      </CustomStack>
    </Box>
  );
}

export default ShippingSteps;

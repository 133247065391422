import React from "react";

function MyOrderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M17.4352 19.7245H3.70215C2.91788 19.7237 2.16601 19.4118 1.61145 18.8572C1.05689 18.3026 0.744935 17.5507 0.744141 16.7665V3.03046C0.743874 2.24513 1.05518 1.49177 1.60974 0.935707C2.1643 0.379646 2.91682 0.0663071 3.70215 0.0644531H17.4382C18.2246 0.0652473 18.9785 0.377991 19.5345 0.934052C20.0906 1.49011 20.4034 2.24407 20.4042 3.03046V16.7665C20.4023 17.5523 20.0886 18.3053 19.5319 18.8599C18.9752 19.4146 18.221 19.7255 17.4352 19.7245ZM3.70215 2.06646C3.57582 2.06698 3.45087 2.09238 3.33435 2.14121C3.21784 2.19004 3.11203 2.26134 3.02307 2.35104C2.93411 2.44075 2.86378 2.54709 2.81592 2.66401C2.76806 2.78093 2.74361 2.90612 2.74414 3.03246V16.7665C2.74441 17.0205 2.84542 17.264 3.02502 17.4436C3.20463 17.6232 3.44815 17.7242 3.70215 17.7245H17.4382C17.6932 17.725 17.938 17.6245 18.119 17.4449C18.3001 17.2654 18.4026 17.0214 18.4042 16.7665V3.03046C18.4039 2.77434 18.3021 2.52879 18.121 2.34769C17.9399 2.16658 17.6944 2.06472 17.4382 2.06445L3.70215 2.06646Z"
        fill="black"
      />
      <path
        d="M12.5323 6.9744H8.61133C8.34611 6.9744 8.09171 6.86905 7.90417 6.68151C7.71664 6.49397 7.61133 6.23962 7.61133 5.9744V1.06641C7.61133 0.80119 7.71664 0.546835 7.90417 0.359299C8.09171 0.171762 8.34611 0.0664062 8.61133 0.0664062H12.5323C12.7976 0.0664062 13.0518 0.171762 13.2394 0.359299C13.4269 0.546835 13.5323 0.80119 13.5323 1.06641V5.9764C13.5318 6.24127 13.4262 6.49512 13.2388 6.68222C13.0513 6.86932 12.7972 6.9744 12.5323 6.9744ZM9.61133 4.9744H11.5323V2.06641H9.61133V4.9744Z"
        fill="black"
      />
    </svg>
  );
}




export default MyOrderIcon;

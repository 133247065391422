import { useState } from "react";

type UseModalReturnType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

/**
 * Generates a custom hook for managing the state of modal open/close functionality.
 *
 * @return {UseModalReturnType} An object containing the isOpen state, openModal function, and closeModal function.
 */
const useOpenCloseModal = (): UseModalReturnType => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    openModal,
    closeModal,
  };
};

export default useOpenCloseModal;

/**
 * Create the store with dynamic reducers
 */

import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createRootReducer } from "./rootReducers";
import { wiztexApi } from "./apiService";
import { TypedUseSelectorHook, useDispatch,useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
// import { createInjectorsEnhancer } from "redux-injectors";

const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
const { run: runSaga } = sagaMiddleware;

// Create the store with saga middleware
const middlewares = [sagaMiddleware];

// const enhancers = [
//   createInjectorsEnhancer<any>({
//     createReducer,
//     runSaga,
//   }),
// ] as StoreEnhancer[];

const store = configureStore({
  reducer: {
    [wiztexApi.reducerPath]: wiztexApi.reducer,
    ...createRootReducer(),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(wiztexApi.middleware),
  ...middlewares,
  // reducer: createReducer(),
  // devTools: process.env.NODE_ENV !== JSON.stringify("production"),
  // enhancers,
});
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

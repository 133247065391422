import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
}

interface BreadcrumbsProps {
  items: { label: string; href?: string }[];
}

const DynamicBreadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {

  return (
    <div role="presentation" onClick={handleClick} style={{ margin: "10px 10px" }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />}>
        {items.map((item, index) => {
          if (item.href) {
            return (
              <Link key={index} underline="hover" color="inherit" component={RouterLink} to={item.href}>
                {item.label}
              </Link>
            );
          } else {
            return (
              <Typography key={index} color="text.primary">
                {item.label}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default DynamicBreadcrumbs;

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";
import "./app.css";
import { HomePage } from "./pages/HomePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { useTranslation } from "react-i18next";
import { Layout } from "./components/Shared";
import { ProductDetail } from "./components";

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - wiztex"
        defaultTitle="wiztex"
        htmlAttributes={{ lang: i18n.language }}>
        <meta name="description" content="wiztex app" />
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/productDetail/:productId" element={<ProductDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
      <GlobalStyle />
    </BrowserRouter>
  );
}

import React from "react";
interface Props {
  images: string[];
  handleImgChange: (e: any, index: number) => void;
  smImgsRef: React.RefObject<HTMLDivElement>;
  activeIndex: number;
}
function ProductDetailThumb({ images, handleImgChange, smImgsRef,activeIndex }: Props) {
  console.log(activeIndex)
  return (
    <div className="thumb" ref={smImgsRef}>
    {images.map((img, index) => (
      <img
        src={img}
        alt="small img"
        key={index}
        className={index === activeIndex ? 'active' : ''}
        onClick={(e) => handleImgChange(e,index)}
      />
    ))}
  </div>
  );
}

export default ProductDetailThumb;